import React from 'react';
import { graphql, Link } from 'gatsby';
import { getKeyedResources } from '../../../utils/resource-helper';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import Subfooter from '../../../components/Subfooter';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Hero from '../../../components/Hero';
import Resource from '../../../components/Resource';
import bgImage from '../../../images/background-physics.jpg';
import '../../../styles/pages/_unit-sections.scss';

const Physics = ({ data }) => {
  /* const heroBreadcrumbs = {
    linkLabel: 'PHYSICS OF THE UNIVERSE',
    to: '/classroom-instruction/physics/',
  }; */

  const {
    physApplyingSpectroscopy,
    physGeneratingTheFuture,
    physRidingTheWave,
    physUncoveringPlasmasPromise,
  } = getKeyedResources(data);

  return (
    <Layout title="Physics of the Universe">
      {/* -----------------UNIT BANNER----------------------- */}
      <Hero
        className={'hero--small course__hero'}
        modal
        imageSource="header-physics-unit1@2x.jpg"
        guidSrc="116f5b2b-b703-4fb7-a85e-a3b74e7422cd"
        posterSrc={'unit-1-physics-intro-video-still.gif'}
        // breadcrumbs={heroBreadcrumbs}
        color={'teal'}
      >
        <h1 className=" mb-0 pb-0">PHYSICS OF THE UNIVERSE</h1>
        <p className="pb-2">
          Reimagine the elements of physics as students apply <br />{' '}
          cutting-edge biotechnology applications to their own world.
        </p>
      </Hero>
      {/* ----------------- AT A GLANCE RIBBON ----------------- */}
      <Section className="unit-sections__at-a-glance-section pt-0 pb-0">
        <Container
          fullWidth
          className="unit-sections__at-a-glance-container pt-2 pb-1"
        >
          <div className="course__unit-indicator text-bold">
            <div>PHYSICS OF THE UNIVERSE DIGITAL LESSON BUNDLES</div>
          </div>
          <h4 className="mt-0.5 text-bold">At a Glance</h4>
          <Row>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-storyline.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                STORYLINE
              </h3>
              <ul className="course__list course__list-style-none">
                <li className="mr-3">
                  Connect students to their world and the universe beyond by
                  exploring the physics principles that make possible
                  groundbreaking innovations in biotech.
                </li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-pacing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                PACING
              </h3>
              <ul className="course__list">
                <li>4 Three-Day Lessons</li>
                <li>4 Classroom Presentations</li>
                <li>Flexible Extension Resources</li>
                <li>45-Minute Sessions</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-topics-covered.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                TOPICS COVERED
              </h3>
              <ul className="course__list">
                <li>Electromagnetic Radiation</li>
                <li>Plasma</li>
                <li>Biomedicine</li>
                <li>Energy Production</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-framing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                FRAMING
              </h3>
              <ul className="course__list">
                <li>NGSS Aligned</li>
                <li>Project Driven</li>
                <li>Data Based</li>
                <li>Grounded in Inquiry</li>
              </ul>
            </Column>
          </Row>
        </Container>
      </Section>
      {/* ----------------- COURSE CARDS INTRO ----------------- */}
      <Section
        style={{ backgroundImage: `url(${bgImage})` }}
        className="pt-0 pb-3"
      >
        <Container fullWidth className="pt-4 pb-1">
          <Row className="mb-2">
            <Column size={7}>
              <h3 className="text-bold">Getting Started</h3>
              <p>
                Bring biotechnology to life within the bigger picture of physics
                with a variety of biotech explorations flexibly designed for any
                learning environment. Explore standards-aligned activities that
                emphasize research, collaboration, and creativity.
              </p>
            </Column>
            {/* Full unit zip file download */}
            <Column offset={1} size={4}>
              <div className="unit_download">
                <a href="/units/Gen-Phys.zip" className="button">
                  <Icon marginRight name="download" />
                  Complete Unit
                </a>
                <span className="unit_download">ZIP, 28.2 MB</span>
              </div>
            </Column>
          </Row>
        </Container>
        {/* ----------------- COURSE CARDS ----------------- */}
        <Container fullWidth>
          <Row>
            {/* LESSON 1*/}
            <Column medium={6} small={6} xsmall={12}>
              <Resource
                tophat={physApplyingSpectroscopy.tophat}
                tophatClass={`${physApplyingSpectroscopy.page}__resource-tophat`}
                ribbon={physApplyingSpectroscopy.ribbon}
                ribbonClass={`${physApplyingSpectroscopy.page}__resource-ribbon`}
                img=""
                duration={physApplyingSpectroscopy.duration}
                subject={physApplyingSpectroscopy.subject}
                subjectClass={`${physApplyingSpectroscopy.page}__resource-subject`}
                title={physApplyingSpectroscopy.title}
                description={physApplyingSpectroscopy.description}
                actions={physApplyingSpectroscopy.actions}
              />
            </Column>
            {/* LESSON 2 */}
            <Column medium={6} small={6} xsmall={12}>
              <Resource
                tophat={physGeneratingTheFuture.tophat}
                tophatClass={`${physGeneratingTheFuture.page}__resource-tophat`}
                ribbon={physGeneratingTheFuture.ribbon}
                ribbonClass={`${physGeneratingTheFuture.page}__resource-ribbon`}
                img=""
                duration={physGeneratingTheFuture.duration}
                lab={physGeneratingTheFuture.lab}
                subject={physGeneratingTheFuture.subject}
                subjectClass={`${physGeneratingTheFuture.page}__resource-subject`}
                title={physGeneratingTheFuture.title}
                description={physGeneratingTheFuture.description}
                actions={physGeneratingTheFuture.actions}
              />
            </Column>
            {/* LESSON 3 */}
            <Column medium={6} small={6} xsmall={12}>
              <Resource
                tophat={physRidingTheWave.tophat}
                tophatClass={`${physRidingTheWave.page}__resource-tophat`}
                ribbon={physRidingTheWave.ribbon}
                ribbonClass={`${physRidingTheWave.page}__resource-ribbon`}
                img=""
                duration={physRidingTheWave.duration}
                subject={physRidingTheWave.subject}
                subjectClass={`${physRidingTheWave.page}__resource-subject`}
                title={physRidingTheWave.title}
                description={physRidingTheWave.description}
                actions={physRidingTheWave.actions}
              />
            </Column>
            {/* LESSON 4 */}
            <Column medium={6} small={6} xsmall={12}>
              <Resource
                tophat={physUncoveringPlasmasPromise.tophat}
                tophatClass={`${physUncoveringPlasmasPromise.page}__resource-tophat`}
                ribbon={physUncoveringPlasmasPromise.ribbon}
                ribbonClass={`${physUncoveringPlasmasPromise.page}__resource-ribbon`}
                img=""
                duration={physUncoveringPlasmasPromise.duration}
                subject={physUncoveringPlasmasPromise.subject}
                subjectClass={`${physUncoveringPlasmasPromise.page}__resource-subject`}
                title={physUncoveringPlasmasPromise.title}
                description={physUncoveringPlasmasPromise.description}
                actions={physUncoveringPlasmasPromise.actions}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0 pt-0">
        <Subfooter content="Cultivating lifelong learners to solve the most pressing social challenges in science and beyond." />
      </Section>
    </Layout>
  );
};

export default Physics;

export const query = graphql`
  query PhysicsResources {
    allResourcesJson(filter: { page: { eq: "physics" }, unit: { eq: 1 } }) {
      edges {
        node {
          slug
          unit
          page
          ribbon
          tophat
          img
          subject
          title
          duration
          lab
          description
          actions {
            label
            type
            format
            size
            path
            title
            clickLabel
            clickType
            actions {
              label
              type
              format
              size
              path
              title
              clickLabel
              clickType
              guidSrc
              posterSrc
            }
          }
        }
      }
    }
  }
`;
